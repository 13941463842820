<template lang="html">
  <div class="main">
    <Splide :options="options">
      <SplideSlide>
        <div class="img img_one">
          <div class="container">
            <div class="text">
              <h1 class="pb-2">
                Premium quality liquid dish wash Your Kitchen cleaning expert
              </h1>
              <p>
                With our knowledge and expertise we have been able to provide
                the most efficient, effective and environmentally friendly
                cleaning solution for every home.
              </p>
            </div>
          </div>
        </div>
      </SplideSlide>
      <SplideSlide>
        <div class="img img_two">
          <div class="container">
            <h2>Innovative, Safe and Effective liquid detergents</h2>
          </div>
        </div>
      </SplideSlide>
      <SplideSlide>
        <div class="img img_three">
          <div class="container">
            <div class="text">
              <h3 class="pb-2">
                Mosturizing BQHANDWASH. Germs away in seconds
              </h3>
              <p>
                With our knowledge and expertise we have been able to provide
                the most efficient, effective and environmentally friendly
                cleaning solution for every home.
              </p>
            </div>
          </div>
        </div>
      </SplideSlide>
    </Splide>
  </div>
  <section class="section py-3">
    <div class="container">
      <div class="button, as">
        <button class="pri-btn" @click="$router.push({ name: 'products' })">
          Buy Now <span class="mdi mdi-chevron-right"></span>
        </button>
        <a :href="distributorForm" target="_blank" class="sec-btn"
          >Become a Distributor</a
        >
      </div>
    </div>
  </section>
  <aside class="aside py-3">
    <div class="container">
      <div class="text">
        <p>
          Healthy families start with healthy choices, start making these
          choices by integrating our innovative, eco-friendly detergents into
          your household essentials.
        </p>
      </div>
    </div>
  </aside>
</template>
<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
export default defineComponent({
  name: "HomeMain",
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      options: {
        type: "loop",
        drag: false,
        focus: "center",
        perPage: 1,
        easing: "ease",
        autoplay: true,
        interval: 2000,
        arrows: false,
      },
    };
  },
  computed: {
    ...mapGetters(["distributorForm"]),
  },
  methods: {
    ...mapActions(["getForm"]),
  },
  mounted() {
    this.getForm();
  },
});
</script>
<style lang="scss" scoped>
@import "@/scss/style.scss";
.main {
  margin-top: 78px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .img {
    height: 500px;
    height: 400px;
    h1,
    h2,
    h3 {
      @include font(23px, 700, 30px, $red);
      text-shadow: 1px 1px 1px #692d1e;
      padding: 5rem 0 1rem;
    }
    p {
      color: $red;
    }
  }
  .img_one {
    background: url("../../assets/img/bg-01.svg") no-repeat center center/cover;
  }
  .img_two {
    background: url("../../assets/img/bg-02.svg") no-repeat center center/cover;
  }
  .img_three {
    background: url("../../assets/img/bg-03.svg") no-repeat center center/cover;
    .text {
      h3,
      p {
        color: $white;
      }
    }
  }
}

.section {
  p {
    @include font(16px, 400, 25px, $red);
    text-align: center;
  }
  button,
  a {
    display: block;
    width: 90%;
    margin: auto;
    text-align: center;
  }
  .pri-btn {
    margin-bottom: 1rem;
    span {
      background: $red;
      @include font(16px, 600, 22px, $white);
      border-radius: 50%;
    }
  }
}
.aside {
  text-align: center;
  background: $pri-btn;
  p {
    color: $white;
  }
}
@media screen and (min-width: 700px) {
  .main {
    .img {
      height: 400px;
      .text {
        width: 500px;
        margin: 0;
      }
    }
  }
  .section {
    @include flex(flex, center, "", "");
    p {
      width: 650px;
      margin: auto;
    }
    .buttons {
      @include flex(flex, flex-start, center, row);
    }
    button,
    a {
      width: auto;
      margin: 0;
      display: inline;
    }
    .pri-btn {
      margin: 0 1rem 0 0;
    }
  }
}

@media screen and (min-width: 1000px) {
  .main {
    .img {
      height: 85vh;
      h1,
      h2,
      h3 {
        margin: 0;
        @include font(40px, 700, 45px, $red);
      }
      .text {
        width: 650px;
        margin: 0;
      }
    }
  }
  .aside {
    .container {
      width: 800px;
      margin: auto;
    }
    p {
      @include font(18px, 600, 32px, $white);
    }
  }
}
</style>
