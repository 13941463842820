<template>
  <div class="distributors pb-3">
    <div class="container">
      <div class="see mt-2">
        <p class="title pb-3"><slot></slot></p>
        <div class="see-cards">
          <iframe
            :src="src"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeVids",
  data() {
    return {};
  },
  props: {
    src: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/style.scss";
.distributors {
  p.title {
    @include font(25px, 700, 30px, $pri-color);
  }
  .see {
    text-align: center;
    .see-cards {
      .see-card {
        margin-bottom: 1rem;
      }
    }
  }
  iframe {
    width: 100%;
    height: 400px;
    border-radius: 12px;
  }
}
@media screen and (min-width: 1000px) {
  .distributors {
    p.title {
      @include font(36px, 700, 36px, $pri-color);
      margin: auto;
    }
  }
  iframe {
    width: 850px !important;
    height: 400px;
    margin: auto;
  }
}
</style>
