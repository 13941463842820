import axios from "axios";
import router from "@/router";

const baseUrl = "https://bq-safe-be.herokuapp.com/api/v1";
const cart = JSON.parse(localStorage.getItem("cart"));
const token = sessionStorage.getItem("token");
function errorStatus(state) {
  state.error = true;
  state.isLoading = false;
  setTimeout(() => {
    state.error = false;
  }, 2000);
}
const state = {
  cart: cart ? cart : [],
  token: token ? token : "",
  products: [],
  product: "",
  feedbacks: [],
  rates: [],
  blogs: [],
  distributors: [],
  states: [],
  locations: [],
  distributorForm: "",
  distributor: "",
  summary: "",
  feedback: "",
  order_details: "",
  payment_details: "",
  rate: "",
  blog: "",
  user: "",
  showAlert: false,
  success: false,
  isLoading: false,
  modal: false,
  error: false,
};
const getters = {
  cart: (state) => state.cart,
  token: (state) => state.token,
  products: (state) => state.products,
  product: (state) => state.product,
  feedbacks: (state) => state.feedbacks,
  distributors: (state) => state.distributors,
  rates: (state) => state.rates,
  rate: (state) => state.rate,
  blogs: (state) => state.blogs,
  blog: (state) => state.blog,
  user: (state) => state.user,
  distributorForm: (state) => state.distributorForm,
  distributor: (state) => state.distributor,
  summary: (state) => state.summary,
  showAlert: (state) => state.showAlert,
  success: (state) => state.success,
  order_details: (state) => state.order_details,
  payment_details: (state) => state.payment_details,
  states: (state) => state.states,
  locations: (state) => state.locations,
  isLoading: (state) => state.isLoading,
  modal: (state) => state.modal,
  error: (state) => state.error,
};
const actions = {
  // ###### Products
  async addProduct({ commit, state }, products) {
    state.isLoading = true;
    try {
      const response = await axios.post(`${baseUrl}/admin/products`, products, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      commit("addProduct", response.data.data);
      router.push({ name: "admin" });
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async getProducts({ commit, state }) {
    state.isLoading = true;
    try {
      const response = await axios.get(
        `${baseUrl}/products?page=1&page_size=50&category_id=3fjasdkfjj879593248_gjdskfn`
      );
      commit(
        "getProducts",
        response.data.data.sort((a, b) => (a.name > b.name ? 1 : -1))
      );
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async getAProduct({ commit, state }, id) {
    state.isLoading = true;
    try {
      const response = await axios.get(`${baseUrl}/product/${id}`);
      commit("getAProduct", response.data.data);
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async editProduct({ commit, state }, product) {
    state.isLoading = true;
    try {
      const response = await axios.put(`${baseUrl}/admin/products`, product, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      commit("editProduct", response.data.data);
      router.push({ name: "admin" });
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async deleteProduct({ commit, state }, id) {
    try {
      await axios.delete(`${baseUrl}/admin/products/${id}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      commit("deleteProduct", id);
    } catch (error) {
      errorStatus(state);
      if (error.response.data.message === "bearer token is invalid") {
        router.push({ name: "login" });
      }
    }
  },
  // ###### Shipping Rates
  async getShippingRates({ commit }) {
    try {
      const response = await axios.get(
        `${baseUrl}/shipping/rate?page=1&page_size=700`
      );
      commit(
        "getShippingRates",
        response.data.data.sort((a, b) =>
          a.area?.name > b.area?.name ? 1 : -1
        )
      );
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async getPaginatedShippingRates({ commit, state }, limit) {
    state.isLoading = true;
    try {
      const response = await axios.get(
        `${baseUrl}/shipping/rate?page=1&page_size=${limit}`
      );
      commit(
        "getShippingRates",
        response.data.data.sort((a, b) =>
          a.area?.name > b.area?.name ? 1 : -1
        )
      );
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async addShippingRate({ commit, state }, shipping_rate) {
    state.isLoading = true;
    try {
      const response = await axios.post(
        `${baseUrl}/admin/shipping/rate`,
        shipping_rate,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      commit("addShippingRate", response.data.data);
      router.push({ name: "admin" });
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async getAShippingRate({ commit, state }, area_id) {
    state.isLoading = true;
    try {
      const response = await axios.get(`${baseUrl}/shipping/rate/${area_id}`);
      commit("getAShippingRate", response.data.data);
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async editShippingRate({ commit, state }, shipping_rate) {
    state.isLoading = true;
    try {
      const response = await axios.put(
        `${baseUrl}/admin/shipping/rate/${state.rate.id}`,
        shipping_rate,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      commit("editShippingRate", response.data.data);
      router.push({ name: "admin" });
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async deleteShippingRate({ commit, state }, id) {
    try {
      await axios.delete(`${baseUrl}/admin/shipping/rate/${id}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      commit("deleteShippingRate", id);
    } catch (error) {
      errorStatus(state);
      if (error.response.data.message === "bearer token is invalid") {
        router.push({ name: "login" });
      }
    }
  },
  // ###### Blogs
  async addBlog({ commit, state }, blog) {
    state.isLoading = true;
    try {
      const response = await axios.post(
        `${baseUrl}/admin/blog/contents`,
        blog,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      commit("addBlog", response.data.data);
      router.push({ name: "admin" });
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async getBlogs({ commit, state }) {
    state.isLoading = true;
    try {
      const response = await axios.get(`${baseUrl}/blog/contents`);
      commit("getBlogs", response.data.data);
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async getABlog({ commit, state }, id) {
    state.isLoading = true;
    try {
      const response = await axios.get(`${baseUrl}/blog/contents/${id}`);
      commit("getABlog", response.data.data);
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async editBlog({ commit, state }, blog) {
    state.isLoading = true;
    try {
      const response = await axios.put(
        `${baseUrl}/admin/blog/contents/${state.blog.id}`,
        blog,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      commit("editBlog", response.data.data);
      router.push({ name: "admin" });
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async deleteBlog({ commit, state }, id) {
    try {
      await axios.delete(`${baseUrl}/admin/blog/contents/${id}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      commit("deleteBlog", id);
    } catch (error) {
      errorStatus(state);
      if (error.response.data.message === "bearer token is invalid") {
        router.push({ name: "login" });
      }
    }
  },
  // ###### Distributors
  async getForm({ commit }) {
    try {
      const response = await axios.get(`${baseUrl}/distributors/request`);
      commit("getForm", response.data.data.url);
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async getDistributors({ commit }) {
    try {
      const response = await axios.get(
        `${baseUrl}/distributors?page=1&page_size=100`
      );
      commit(
        "getDistributors",
        response.data.data.sort((a, b) =>
          a.store_name > b.store_name ? 1 : -1
        )
      );
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async getDistributorsByStatus({ commit, state }, limit) {
    state.isLoading = true;
    try {
      const response = await axios.get(
        `${baseUrl}/admin/distributors/status?status=all&page=1&page_size=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      commit(
        "getDistributors",
        response.data.data.sort((a, b) =>
          a.store_name > b.store_name ? 1 : -1
        )
      );
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async getADistributor({ commit, state }, distributor) {
    state.isLoading = true;
    try {
      const response = await axios.get(
        `${baseUrl}/distributors/${distributor}`
      );
      commit("getADistributor", response.data.data);
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async editDistributor({ commit, state }, distributor) {
    state.isLoading = true;
    try {
      const response = await axios.put(
        `${baseUrl}/admin/distributors/${state.distributor.id}`,
        distributor,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      commit("editDistributor", response.data.data);
      router.push({ name: "admin" });
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async deleteDistributor({ commit, state }, id) {
    try {
      await axios.delete(`${baseUrl}/admin/distributors/${id}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      commit("deleteDistributor", id);
    } catch (error) {
      errorStatus(state);
      if (error.response.data.message === "bearer token is invalid") {
        router.push({ name: "login" });
      }
    }
  },
  async acceptDistributor({ commit, state }, { id, status }) {
    state.isLoading = true;
    try {
      await axios.patch(
        `${baseUrl}/admin/distributors/status/${id}?status=${status}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      commit("acceptDistributor", { id, status });

      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  // ###### Summary
  async getSummary({ commit }) {
    try {
      const response = await axios.get(`${baseUrl}/analytics/summary`);
      commit("getSummary", response.data.data);
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  // ###### Feedback
  async getFeedback({ commit, state }) {
    state.isLoading = true;
    try {
      const response = await axios.get(
        `${baseUrl}/feedback?page=1&page_size=50`
      );
      commit("getFeedback", response.data.data);
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async sendFeedback({ commit, state }, feedback) {
    state.isLoading = true;
    try {
      const response = await axios.post(`${baseUrl}/feedback`, feedback);
      commit("sendFeedback", response.data.data);
      state.success = true;
      state.isLoading = false;
      setTimeout(() => {
        state.success = false;
      }, 1000);
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  // ###### Checkout Process
  removeFromCart({ commit }, id) {
    commit("removeFromCart", id);
    commit("saveCart");
  },
  async placeOrder({ commit, state }, cart_item) {
    state.isLoading = true;
    try {
      const response = await axios.post(
        `${baseUrl}/order?save=true`,
        cart_item
      );
      commit("placeOrder", response.data.data);
      router.replace({ path: "/checkout" });
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async initiatePayment({ commit }, order_id) {
    try {
      const response = await axios.post(`${baseUrl}/payment`, order_id);
      commit("initiatePayment", response.data.data);
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async confirmPayment({ commit, state }, { reference, status }) {
    state.isLoading = true;
    try {
      const response = await axios.get(
        `${baseUrl}/payment/confirm?reference=${reference}&send_email=${status}`
      );
      commit("confirmPayment", response.data.data);
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async getStates({ commit, state }) {
    try {
      const response = await axios.get(
        "https://locationsng-api.herokuapp.com/api/v1/states"
      );
      commit("getStates", response.data);
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async getLocations({ commit, state }) {
    try {
      const response = await axios.get(
        `${baseUrl}/shipping/locations/state?page=1&page_size=50`
      );
      commit(
        "getLocations",
        response.data.data.sort((a, b) => (a.name > b.name ? 1 : -1))
      );
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  // ###### Auth
  async login({ commit, state }, user_details) {
    state.isLoading = true;
    try {
      const response = await axios.post(`${baseUrl}/admin/login`, user_details);
      commit("login", response.data.data.access_token);
      router.push({ name: "admin" });
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
      }
    }
  },
  async updateDetails({ commit, state }, user_details) {
    state.isLoading = true;
    try {
      const response = await axios.put(`${baseUrl}/admin/info`, user_details, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      commit("updateDetails", response.data.data);
      state.success = true;
      state.isLoading = false;
      setTimeout(() => {
        state.success = false;
      }, 1000);
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async updatePassword({ commit, state }, user_details) {
    state.isLoading = true;
    try {
      const response = await axios.put(
        `${baseUrl}/admin/password`,
        user_details,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );
      commit("updatePassword", response.data.data);
      state.success = true;
      state.isLoading = false;
      setTimeout(() => {
        state.success = false;
      }, 1000);
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
  async logOut({ commit, state }, access_token) {
    try {
      await axios.delete(
        `${baseUrl}/admin/logout`,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        },
        access_token
      );
      commit("logOut");
      sessionStorage.removeItem("token");
      router.push({ name: "login" });
    } catch (error) {
      errorStatus(state);
      if (error.response.data.message === "bearer token is invalid") {
        router.push({ name: "login" });
      }
    }
  },
  async getDetails({ commit, state }) {
    state.isLoading = true;
    try {
      const response = await axios.get(`${baseUrl}/admin/info`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      commit("getDetails", response.data.data);
      state.isLoading = false;
    } catch (error) {
      if (error) {
        errorStatus(state);
        if (error.response.data.message === "bearer token is invalid") {
          router.push({ name: "login" });
        }
      }
    }
  },
};
const mutations = {
  // ###### Products
  getProducts(state, payload) {
    state.products = payload;
  },
  getAProduct(state, payload) {
    state.product = payload;
  },
  addProduct(state, payload) {
    state.products.push(payload);
  },
  editProduct(state, payload) {
    state.product = payload;
  },
  deleteProduct(state, id) {
    state.products = state.products.filter((product) => product.id !== id);
  },
  // ###### Shipping Rates
  getShippingRates(state, payload) {
    state.rates = payload;
  },
  getAShippingRate(state, payload) {
    state.rate = payload;
  },
  addShippingRate(state, payload) {
    state.rates.push(payload);
  },
  editShippingRate(state, payload) {
    state.rate = payload;
  },
  deleteShippingRate(state, id) {
    state.rates = state.rates.filter((rate) => rate.id !== id);
  },
  // ###### Blogs
  getBlogs(state, payload) {
    state.blogs = payload;
  },
  getABlog(state, payload) {
    state.blog = payload;
  },
  addBlog(state, payload) {
    state.blogs.push(payload);
  },
  editBlog(state, payload) {
    state.blog = payload;
  },
  deleteBlog(state, id) {
    state.blogs = state.blogs.filter((blog) => blog.id !== id);
  },
  // ###### Distributors
  getForm(state, payload) {
    state.distributorForm = payload;
  },
  getDistributors(state, payload) {
    state.distributors = payload;
  },
  getADistributor(state, payload) {
    state.distributor = payload;
  },
  editDistributor(state, payload) {
    state.distributor = payload;
  },
  deleteDistributor(state, id) {
    state.distributors = state.distributors.filter(
      (distributor) => distributor.id !== id
    );
  },
  acceptDistributor(state, { id, status }) {
    const index = state.distributors.findIndex(
      (distributor) => distributor.id === id
    );
    state.distributors[index].status = status;
  },
  // ###### Summary
  getSummary(state, payload) {
    state.summary = payload;
  },
  // ###### Feedback
  sendFeedback(state) {
    state.feedback;
  },
  getFeedback(state, payload) {
    state.feedbacks = payload;
  },
  // ###### Checkout Process
  resetAlert(state) {
    state.showAlert = false;
  },
  addProductToCart(state, item) {
    const single_product = state.cart.find((cart) => cart.id === item.id);
    if (single_product) {
      single_product.quantity++;
    } else {
      state.cart.push(item);
    }
    state.showAlert = true;
    setTimeout(() => {
      state.showAlert = false;
    }, 2000);
    this.commit("saveCart");
  },
  saveCart(state) {
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },
  removeFromCart(state, id) {
    state.cart = state.cart.filter((item) => item.id !== id);
  },
  clearCart(state) {
    state.cart = [];
    localStorage.removeItem("cart");
  },
  placeOrder(state, payload) {
    state.order_details = payload;
  },
  initiatePayment(state, payload) {
    state.payment_details = payload;
  },
  confirmPayment(state, payload) {
    state.order_details = payload;
  },
  getStates(state, payload) {
    state.states = payload;
  },
  getLocations(state, payload) {
    state.locations = payload;
  },
  // ###### Auth
  login(state, payload) {
    state.token = payload;
    sessionStorage.setItem("token", payload);
  },
  getDetails(state, payload) {
    state.user = payload;
  },
  updateDetails(state, payload) {
    state.user = payload;
  },
  updatePassword(state) {
    state.user;
  },
  logOut(state) {
    state.user;
  },
  toggleModal(state) {
    state.modal = !state.modal;
  },
  resetModal(state) {
    state.modal = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
