<template>
  <div class="essentials py-3">
    <div class="container">
      <div class="essentials-content">
        <h4 class="mb-1">Our Essentials</h4>
      </div>
      <div class="products mt-3">
        <div v-for="product in products" :key="product" class="product">
          <img
            :src="require(`@/assets/img/${product.src}`)"
            :alt="product.name"
          />
          <p class="pb-1">{{ product.name }}</p>
          <button class="sec-btn" @click="$router.push({ name: 'products' })">
            Shop Now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeEssentials",
  data() {
    return {
      products: [
        {
          src: "product-01.png",
          name: "BQ Utensil Wash",
        },
        {
          src: "product-02.png",
          name: "BQSAFEWASH Laundry Detergent",
        },
        {
          src: "product-03.png",
          name: "BQ Hand Wash ",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/style.scss";
.essentials {
  &-content {
    text-align: center;
    h4 {
      @include font(30px, 700, 40px, $red);
    }
    p:last-child {
      color: $sec-color;
    }
  }
}

.products {
  .product {
    position: relative;
    text-align: center;
    margin-bottom: 1rem;
    img {
      height: 400px;
    }
    &::after {
      position: absolute;
      top: 30px;
      right: -35px;
      content: "";
      background: url("@/assets/img/seal.svg") no-repeat;
      width: 100px;
      height: 100px;
    }
  }
}

@media screen and (min-width: 700px) {
  .products {
    @include grid(grid, 3, 1fr, 1rem, 1rem);
    .product {
      margin: 0;
    }
  }
}

@media screen and (min-width: 1000px) {
  .essentials {
    &-content {
      text-align: center;
      width: 800px;
      margin: auto;
      h4 {
        @include font(36px, 700, 49px, $red);
      }
      p:last-child {
        @include font(18px, 300, 32px, $sec-color);
      }
    }
  }
  .products {
    @include grid(grid, 3, 1fr, 1rem, 1rem);
    .product {
      p {
        @include font(20px, 700, 33px, $pri-color);
      }
    }
  }
}
</style>
