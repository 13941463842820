<template>
  <div class="reviews py-3">
    <div class="container">
      <div class="review-flex">
        <div class="reviews-content">
          <p class="tag">customer reviews</p>
          <p class="content mb-1">Over 3,000 customers with 5-star reviews</p>
          <div class="ratings">
            <span class="mdi mdi-star"></span>
            <p class="review-count">
              4.7 <span class="review">(3,000 reviews)</span>
            </p>
          </div>
        </div>
        <div class="card">
          <div
            class="review-card p-1"
            v-for="review in reviews"
            :key="review.id"
            ref="reviews"
          >
            <div class="review-flex mt-1">
              <span class="apostrophe">“</span>
              <div class="stars">
                <span class="mdi mdi-star" v-for="i in 5" :key="i"></span>
              </div>
            </div>
            <p class="comment">
              {{ review.comment }}
            </p>
          </div>
          <span class="mdi mdi-chevron-right right" @click="rightArrow"></span>
          <span class="mdi mdi-chevron-left left" @click="leftArrow"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// const images = document.querySelectorAll(".review-card");
export default {
  data() {
    return {
      reviews: [
        {
          id: 1,
          comment:
            "Being a distributor has not only helped me scale up financially but also health wise.",
        },
        {
          id: 2,
          comment:
            "It has been a wonderful experience, I have not had any cause to look back. I've been using it since I gave birth to my son and I haven't had any regrets. Keep up the good work.",
        },
        {
          id: 3,
          comment:
            "It's a very nice product, my neighbor walked past my children's clothes and she had to pause and ask where I got my cloth from, because the smell is so appealing. Kudos.",
        },
        {
          id: 4,
          comment:
            "I love the laundry soap so much. My daughter spits out milk so much and you know it stinks, but with BQSAFEWASH, it removes the stink and also removes the stains completely. I'm alway happy to buy more",
        },
        {
          id: 5,
          comment:
            "I so love it, the fragrance, quality, I love everything about it and it's very affordable to. Keep doing what you're doing guys.",
        },
      ],
      current: 0,
    };
  },
  methods: {
    reset() {
      // Clear all images
      this.$refs.reviews.forEach((review) => {
        review.style.display = "none";
      });
    },
    startSlide() {
      // Init slider
      this.reset();
      this.$refs.reviews[0].style.display = "";
    },
    prev() {
      // Show prev
      this.reset();
      this.$refs.reviews[this.current - 1].style.display = "";
      this.current--;
    },
    next() {
      // show next
      this.reset();
      this.$refs.reviews[this.current + 1].style.display = "";
      this.current++;
    },
    rightArrow() {
      if (this.current === this.$refs.reviews.length - 1) {
        this.current = -1;
      }
      this.next();
    },
    leftArrow() {
      if (this.current === 0) {
        this.current = this.$refs.reviews.length;
      }
      this.prev();
    },
  },
  mounted() {
    this.startSlide();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/style.scss";
.reviews {
  background: #f4f4f467;
  .container {
    overflow: visible;
  }
  &-content {
    margin-bottom: 4rem;
    p.content {
      @include font(30px, 700, 40px, $black);
    }
    img {
      width: 138px;
      height: 48px;
    }
    .ratings {
      @include flex(flex, flex-start, center, row);
      span {
        margin-right: 0.3rem;
        @include font(13, 700, 50px, $red);
      }
      p.review-count {
        @include font(13, 700, 50px, $black);
        span {
          @include font(13, 700, 50px, $sec-color);
        }
      }
    }
  }
  .review-card {
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    background: $white;
    margin: 0.1rem;
    position: relative;
    .review-flex {
      @include flex(flex, space-between, center, row);
      span.apostrophe {
        @include font(45px, 300, 1, $red);
      }
      .stars {
        span {
          @include font(12px, 300, 1px, $red);
        }
      }
    }
    p {
      @include font(12px, 300, 18px, $sec-color);
      padding: 0.5rem 0;
      text-align: center;
    }
    img {
      width: 50px;
      position: absolute;
      top: -25px;
      left: 40px;
    }
  }
  .card {
    position: relative;
    .left,
    .right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: $white;
      padding: 0.3rem 0.4rem;
      border-radius: 50%;
      @include font(16px, 300, 1, $red);
      cursor: pointer;
      border: solid 1px $red;
      transition: all ease 0.25s;
      &:hover {
        color: $white;
        background: $red;
      }
    }
    .left {
      left: -15px;
    }
    .right {
      right: -10px;
    }
  }
}

.show {
  display: block;
}
@media screen and (min-width: 700px) {
  .reviews {
    &-content {
      margin-bottom: 0;
      flex-basis: 50%;
      p.content {
        @include font(25px, 700, 30px, $black);
        width: 300px;
      }
    }
    .review-flex {
      @include flex(flex, space-between, center, row);
    }
    .review-card {
      flex-basis: 50%;
      p {
        width: 250px;
        margin: auto;
      }
    }
  }
}
@media screen and (min-width: 1000px) {
  .reviews {
    &-content {
      p.content {
        @include font(36px, 700, 45px, $black);
        width: 440px;
      }
    }
    .review-card {
      flex-basis: 400px;
      p {
        width: 350px;
        @include font(16px, 300, 25px, $black);
        margin: auto;
      }
    }
  }
}
</style>
