<template>
  <div class="toast" :class="[showAlert === true ? 'show' : '']">
    <p>Added to cart</p>
    <span class="mdi mdi-close-circle" @click="resetAlert"></span>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["resetAlert"]),
  },
  computed: {
    ...mapGetters(["showAlert"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/style.scss";
.toast {
  position: fixed;
  top: 78px;
  right: -100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  transition: right ease-in-out 0.25s;
  width: 160px;
  padding: 0.5rem;
  @include font(16px, 600, 25px, $white);
  background: $pri-btn;
  z-index: 1000;
  @include flex(flex, space-between, center, row);
  span {
    @include font(16px, 500, 25px, $white);
    margin-left: 1rem;
    padding-left: 0.5rem;
    cursor: pointer;
    border-left: solid 1px $white;
  }
}

.show {
  right: 5%;
}
</style>
