<template>
  <div id="distributors" class="pb-3"></div>
  <div class="distributors py-3">
    <div class="container">
      <div class="distributors-content">
        <div class="text">
          <p class="title mb-2">Our Esteemed Distributors</p>
          <p>
            Because more homes have to experience mildness and purity in
            quality, we need you to join our team as well, be a business owner
            (money multiplier) of your own by showing the world what a great
            experience they can have using BQ GLOBAL SOLUTIONS PRODUCTS
          </p>
        </div>
        <div class="cards mt-3">
          <div v-for="distributor in sliced" :key="distributor" class="card">
            <p class="name">{{ distributor.store_name }}</p>
            <p class="address py-1">{{ distributor.address }}</p>
            <div class="links">
              <a
                :href="`tel:+${distributor.phone_number}`"
                class="pri-btn mdi mdi-phone"
              ></a>
              <a
                v-if="distributor.email"
                :href="`mailto:${distributor.email}`"
                class="pri-btn mdi mdi-email"
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="links mt-2">
        <button class="btn" @click="$router.push({ name: 'distributors' })">
          See more <span class="mdi mdi-arrow-right"></span>
        </button>
        <a class="btn" :href="distributorForm" target="_blank">
          Become a distributor <span class="mdi mdi-arrow-right"></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "HomeDistributors",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["distributorForm", "distributors"]),
    sliced() {
      return this.distributors.slice(0, 3);
    },
  },
  methods: {
    ...mapActions(["getForm", "getDistributors"]),
  },
  mounted() {
    this.getForm();
    this.getDistributors();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/style.scss";
.distributors {
  p.title {
    @include font(25px, 700, 30px, $pri-color);
  }
  p {
    color: $sec-color;
  }
  &-content {
    text-align: center;
  }
  .cards {
    .card {
      box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
      margin: 50rem 0.2rem;
      width: 90%;
      margin: 0.2rem auto 1.5rem;
      padding: 1.5rem;
      p.name {
        @include font(18px, 700, 25px, $pri-color);
      }
      p {
        @include font(16px, 300, 22px, $pri-color);
      }
      .links {
        @include flex(inline-flex, center, center, row);
        gap: 0.5rem;
        a.pri-btn {
          padding: 0.4rem;
          border-radius: 50%;
          color: $white;
          @include flex(inline-flex, center, center, row);
        }
      }
    }
  }
  .links {
    @include flex(flex, center, center, column);
    button,
    a {
      @include font(16px, 700, 22px, $red);
      display: inline;
    }
  }
}
@media screen and (min-width: 700px) {
  .distributors {
    .cards {
      @include grid(grid, 3, 1fr, 1rem, 1rem);
      .card {
        margin: 0.1rem;
      }
    }
    .links {
      @include flex(flex, center, center, row);
    }
  }
}
@media screen and (min-width: 1000px) {
  .distributors {
    .text {
      width: 800px;
      margin: auto;
    }
    p.title {
      @include font(36px, 700, 36px, $pri-color);
    }
    p {
      @include font(18px, 300, 32px, $sec-color);
    }
    .cards {
      .card {
        p {
          @include font(18px, 300, 25px, $pri-color);
        }
      }
    }
  }
}
</style>
