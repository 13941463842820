import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "faq" */ "../views/FaqView.vue"),
  },
  {
    path: "/locations",
    name: "locations",
    component: () =>
      import(/* webpackChunkName: "locations" */ "../views/LocationsView.vue"),
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/ProductView.vue"),
  },
  {
    path: "/category",
    name: "category",
    component: () =>
      import(/* webpackChunkName: "category" */ "../views/CategoryView.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    component: () =>
      import(/* webpackChunkName: "cart" */ "../views/CartView.vue"),
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/CheckoutView.vue"),
  },
  {
    path: "/payment",
    name: "payment",
    component: () =>
      import(/* webpackChunkName: "payment" */ "../views/PaymentView.vue"),
  },
  {
    path: "/confirmation",
    name: "confirmation",
    component: () =>
      import(/* webpackChunkName: "faq" */ "../views/ConfirmationView.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "faq" */ "../views/FaqView.vue"),
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () =>
      import(/* webpackChunkName: "blogs" */ "../views/BlogView.vue"),
  },
  {
    path: "/blog/:id",
    name: "blog",
    component: () =>
      import(/* webpackChunkName: "blog" */ "../views/SingleBlog.vue"),
    props: true,
  },
  {
    path: "/distributors",
    name: "distributors",
    component: () =>
      import(
        /* webpackChunkName: "distributors" */ "../views/DistributorsView.vue"
      ),
  },
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/admin/AdminDashboard.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/add-product",
    name: "add-product",
    component: () =>
      import(
        /* webpackChunkName: "add-product" */ "../views/admin/AddProduct.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-product/:id",
    name: "edit-product",
    component: () =>
      import(
        /* webpackChunkName: "edit-product" */ "../views/admin/EditProduct.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/add-blog",
    name: "add-blog",
    component: () =>
      import(/* webpackChunkName: "add-blog" */ "../views/admin/AddBlog.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-blog/:id",
    name: "edit-blog",
    component: () =>
      import(/* webpackChunkName: "edit-blog" */ "../views/admin/EditBlog.vue"),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/add-shipping-rate",
    name: "add-shipping-rate",
    component: () =>
      import(
        /* webpackChunkName: "add-shipping-rate" */ "../views/admin/AddShippingRate.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-shipping-rate/:id",
    name: "edit-shipping-rate",
    component: () =>
      import(
        /* webpackChunkName: "edit-shipping-rate" */ "../views/admin/EditShippingRate.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/edit-distributor/:id",
    name: "edit-distributor",
    component: () =>
      import(
        /* webpackChunkName: "edit-distributor" */ "../views/admin/EditDistributor.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/admin/AdminLogin.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      top: 0,
      behavior: "smooth",
    };
  },
});

router.beforeEach((to) => {
  if (to.meta.requiresAuth && !sessionStorage.getItem("token")) {
    return { name: "login" };
  }
});

export default router;
