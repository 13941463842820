<template>
  <div class="wrapper py-3">
    <!-- <div class="story pt-3">
      <div class="container">
        <div class="story-content">
          <h5 class="tag">our story</h5>
          <p class="pb-1">We are in business to care for your little ones</p>
          <button class="pri-btn" @click="$router.push({ name: 'about' })">
            Read More
          </button>
        </div>
      </div>
      <div class="story-aside pt-3 mt-3">
        <div class="container">
          <div class="aside-flex">
            <div class="wrap">
              <div class="line-c mb-2">
                <div class="line"></div>
                <img src="@/assets/img/circle.svg" alt="circle" />
              </div>
              <div class="story-aside-content">
                <p>
                  With our knowledge and expertise we are able to provide the
                  most efficient, effective and environmentally friendly laundry
                  solution for every home.
                </p>
              </div>
            </div>
            <div class="images">
              <img src="@/assets/img/story-01.svg" alt="story_one" />
              <img src="@/assets/img/story-02.svg" alt="story_two" />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="card-bg">
      <div class="cards">
        <div class="card-flex">
          <div class="card">
            <p>{{ formatNumber(summary.total_sales + 3000) }}</p>
            <p>Total Sale</p>
          </div>
          <div class="card">
            <p>100</p>
            <p>Customer Reviews</p>
          </div>
          <div class="card">
            <p>{{ formatNumber(summary.distributors) }}</p>
            <p>Distributors</p>
          </div>
          <div class="card">
            <p>11</p>
            <p>States</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <aside class="aside py-3">
    <div class="images">
      <img src="@/assets/img/01.jpeg" alt="image_one" />
      <img src="@/assets/img/02.jpg" alt="image_two" />
      <img src="@/assets/img/03.jpg" alt="image_three" />
    </div>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "HomeStory",
  data() {
    return {
      cards: [
        {
          rate: "5K+",
          title: "Total Sale",
        },
        {
          rate: "6.5K+",
          title: "Customer Reviews",
        },
        {
          rate: "20+",
          title: "Distributors",
        },
        {
          rate: "9",
          title: "States",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["summary"]),
  },
  methods: {
    ...mapActions(["getSummary"]),
    formatNumber(value) {
      return new Intl.NumberFormat().format(value);
    },
  },
  mounted() {
    this.getSummary();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/style.scss";
.wrapper {
  position: relative;
}
.story {
  position: relative;
  overflow: hidden;
  &-content {
    p {
      @include font(25px, 700, 30px, $pri-color);
    }
  }
  &-aside {
    background: $pri-btn;
    position: relative;
    .line-c {
      @include flex(inline-flex, flex-start, center, column);
      .line {
        height: 135px;
        width: 4px;
        background: $white;
        margin-bottom: 0.3rem;
      }
      img {
        width: 26px;
        height: 26px;
      }
    }
    p {
      color: $white;
    }
    .aside-flex {
      .images {
        @include flex(flex, flex-start, flex-end, row);
        position: relative;
        img {
          width: 150px;
          &:first-child {
            margin-right: 0.5rem;
          }
        }
        &::after {
          position: absolute;
          top: 25%;
          left: 75%;
          transform: translate(-50%);
          background: url("@/assets/img/seal.svg") no-repeat;
          width: 200px;
          height: 200px;
          z-index: 10;
          content: "";
        }
      }
    }
  }
}
.card-bg {
  background: $pri-btn;
  padding: 1.5rem 0;
  margin: 0;
}
.cards {
  width: 90%;
  margin: auto;
  background: #f0eff4;
  border-radius: 4px;
  padding: 1rem 0;
  text-align: center;
  .card {
    border-bottom: 2px solid #8f8f8f;
    padding: 1rem;
    p:first-child {
      @include font(30px, 600, 35px, $black);
    }
    p:last-child {
      @include font(16px, 600, 24px, $black);
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
.aside {
  width: 90%;
  margin: auto;
  // text-align: center;
  // @include flex(flex, center, center, row);
}
.images {
  img {
    border-radius: 10px;
    margin-bottom: 0.5rem;
    &:last-child {
      margin: 0;
    }
  }
}
@media screen and (min-width: 700px) {
  .story {
    &-content {
      width: 485px;
      margin: 0;
      p {
        @include font(36px, 700, 54px, $pri-color);
      }
    }
    &-aside {
      padding-bottom: 10rem;
      .container {
        position: relative;
        overflow: visible;
      }
      p {
        @include font(14px, 600, 22px, $white);
        width: 289px;
        margin: 0;
      }
    }
    .aside-flex {
      @include flex(flex, space-between, center, row);
      .images {
        position: absolute;
        top: -140px;
        right: 0;
        overflow: hidden;
        img {
          width: 250px;
        }
        img:first-child {
          width: 150px;
        }
        &::after {
          position: absolute;
          top: 53%;
          left: 57%;
        }
      }
    }
  }
  .cards {
    .card-flex {
      @include flex(flex, center, center, row);
    }
    .card {
      padding: 0.5rem 2rem;
      border-right: 2px solid #8f8f8f;
      border-bottom: none;
      @include flex(flex, center, center, column);
      &:last-child {
        border-right: none;
      }
    }
  }
  .images {
    @include grid(grid, 3, 1fr, 1rem, 1rem);
    img {
      margin: 0;
      height: 220px;
      width: 220px;
    }
  }
}
@media screen and (min-width: 1000px) {
  .cards {
    width: 850px;
    bottom: -90px;
    .card {
      padding: 0.5rem 3rem;
      p:first-child {
        @include font(56px, 600, 72px, $black);
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .aside {
    width: 850px;
  }
}
</style>
