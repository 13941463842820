<template>
  <div class="home">
    <the-header />
    <home-main />
    <home-essentials />
    <home-story />
    <home-reviews />
    <our-distributors />
    <universal-vids src="https://www.youtube.com/embed/7wE8s9ZckgI"
      >See us through your lens</universal-vids
    >
    <the-footer />
  </div>
</template>

<script>
import TheHeader from "@/components/navbar/TheHeader.vue";
import HomeMain from "@/components/home/HomeMain.vue";
import HomeEssentials from "@/components/home/HomeEssentials.vue";
import HomeStory from "@/components/home/HomeStory.vue";
import HomeReviews from "@/components/home/HomeReviews.vue";
import OurDistributors from "@/components/reuseables/OurDistributors.vue";
import UniversalVids from "@/components/reuseables/UniversalVids.vue";
import TheFooter from "@/components/navbar/TheFooter.vue";

export default {
  name: "HomeView",
  components: {
    TheHeader,
    HomeMain,
    HomeEssentials,
    HomeStory,
    HomeReviews,
    OurDistributors,
    UniversalVids,
    TheFooter,
  },
};
</script>
