<template>
  <header class="header">
    <div class="container">
      <div class="header-content">
        <div class="logo">
          <router-link to="/"
            ><img src="@/assets/img/logo.png" alt="logo"
          /></router-link>
        </div>
        <nav class="nav" :class="{ open: open }">
          <ul>
            <li v-for="(route, index) in routes" :key="index">
              <router-link
                :to="{ name: route.name }"
                v-if="route.dropdown === null"
                >{{ route.title }}</router-link
              >
              <a href="/#distributors" v-else-if="route.dropdown === false"
                >Become A Distributor</a
              >
              <span v-else>
                <span class="inner-span">
                  <span
                    class="inner-span main-span"
                    @click="dropdown(route.id)"
                  >
                    {{ route.title }}
                    <span
                      class="inner-span mdi"
                      :class="[
                        selected === route.id
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down',
                      ]"
                    ></span>
                  </span>
                </span>
                <ul
                  class="route-list"
                  :class="[selected === route.id ? 'drop' : '']"
                >
                  <li v-for="list in route.lists" :key="list">
                    <router-link :to="{ name: list.name }">{{
                      list.title
                    }}</router-link>
                  </li>
                </ul>
              </span>
            </li>
            <li
              class="cart-wrapper mobile-cart"
              @click="$router.push({ name: 'cart' })"
            >
              <span class="counter">{{ cart.length }}</span>
              <span class="mdi mdi-cart cart"></span>
            </li>
          </ul>
        </nav>
        <div class="flex-nav hide-on-lg">
          <div class="cart-wrapper" @click="$router.push({ name: 'cart' })">
            <span class="counter">{{ cart.length }}</span>
            <span class="mdi mdi-cart cart"></span>
          </div>
          <div @click="openNav" class="hamburger" :class="{ change: open }">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HeaderTag",
  data() {
    return {
      open: false,
      searchBar: false,
      routes: [
        {
          name: "home",
          title: "Home",
          dropdown: null,
        },
        {
          name: "about",
          title: "About Us",
          dropdown: null,
        },
        {
          name: "products",
          title: "Products",
          id: 1,
          dropdown: true,
          lists: [
            {
              name: "products",
              title: "Products",
            },
            {
              name: "category",
              title: "Product Categories",
            },
          ],
        },
        {
          title: "Become A Distributor",
          dropdown: false,
        },
        {
          name: "blogs",
          title: "Blog",
          dropdown: null,
        },
        {
          name: "about",
          title: "Resource Center",
          dropdown: true,
          id: 2,
          lists: [
            {
              name: "contact",
              title: "Contact Us",
            },
            {
              name: "locations",
              title: "Shipping Rates and Locations",
            },
            {
              name: "faq",
              title: "FAQs",
            },
          ],
        },
      ],
      selected: "",
    };
  },
  computed: {
    ...mapGetters(["cart"]),
  },
  methods: {
    openNav() {
      this.open = !this.open;
    },
    dropdown(id) {
      if (this.selected === "") {
        this.selected = id;
      } else {
        this.selected = "";
      }
    },
  },
  mounted() {
    this.$router.beforeEach((from, to, next) => {
      this.open = false;
      next();
    });
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/style.scss";
.header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 78px;
  background: $white;
  display: flex;
  &-content {
    @include flex(flex, space-between, center, row);
  }
}
img {
  width: 100px;
}
.hamburger {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 30px;
  height: 20px;
  z-index: 10;
  & > div {
    height: 2px;
    width: 30px;
    background: $pri-color;
    margin: 0.1rem 0;
    transition: transform ease-in-out 0.4s;
    cursor: pointer;
  }
}
.change.hamburger > div:nth-child(1) {
  transform: translate(1px, 5px) rotate(45deg);
}
.change.hamburger > div:nth-child(2) {
  opacity: 0;
}
.change.hamburger > div:nth-child(3) {
  transform: translate(0px, -6px) rotate(-45deg);
}
.open.nav {
  left: 0;
}
.nav {
  position: fixed;
  top: 78px;
  left: -100%;
  width: 100%;
  height: 100%;
  background: $white;
  transition: all ease 0.25s;
  & ul {
    margin-top: 100px;
    list-style: none;
  }
  & ul li {
    display: block;
    padding: 0;
    margin: 0;
  }
  & a,
  & span {
    display: block;
    text-decoration: none;
    @include font(18px, 500, 30px, $pri-color);
    padding: 1rem;
    text-transform: capitalize;
    & ul {
      margin: 0;
      display: none;
    }
  }
  & span.inner-span {
    padding: 0;
  }
  & .main-span {
    @include flex(flex, space-between, center, row);
  }
  & a.router-link-exact-active {
    font-weight: bold;
    color: $white;
    background: $pri-btn;
  }
  .drop {
    display: block;
  }
}

.cart-wrapper {
  position: relative;
  margin-right: 1rem;
  cursor: pointer;
  .cart {
    background: #fc0f1d;
    padding: 0.25rem 0.5rem;
    border-radius: 50%;
    @include font(10px, 400, 1, $white);
  }
  .counter {
    background: #f87d24;
    padding: 0.25rem 0.4rem;
    border-radius: 50%;
    @include font(8px, 400, 1, $white);
    position: absolute;
    top: -6px;
    right: 0px;
    z-index: 10;
  }
}
.mobile-cart {
  display: none !important;
}

.flex-nav {
  @include flex(flex, flex-start, center, row);
  gap: 0.5rem;
}
@media screen and (min-width: 1000px) {
  %after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    border-radius: 20px;
    transform: translateX(-50%);
    height: 3.5px;
    background: $pri-btn;
    transition: width 0.5s ease-in-out;
  }
  .hamburger {
    display: none;
  }
  .container {
    overflow: visible;
  }
  .nav {
    position: relative;
    width: auto;
    height: auto;
    background: none;
    z-index: 0;
    top: 0;
    left: 0;
    display: block;
    margin: 0;
    & ul {
      padding: 0;
      margin: 0;
      display: block;
    }
    & ul li {
      display: inline;
    }

    & span.inner-span {
      padding: 0 !important;
      &::after {
        background: none !important;
      }
    }
    & .main-span {
      @include flex(flex, flex-start, center, row);
      span.mdi {
        padding: 0 !important;
      }
    }
    & a,
    & span,
    & span.inner-span {
      cursor: pointer;
      @include font(14px, 500, 21px, $nav-color);
      display: inline;
      padding: 0 1rem 0.45rem;
      position: relative;
      transition: all ease 0.5s;
      &::after {
        @extend %after;
        width: 0;
      }
      &:hover::after {
        width: 20px;
      }
      &:hover {
        color: $pri-btn;
      }
      & ul {
        position: absolute;
        top: 60px;
        left: 0;
        width: max-content;
        height: auto;
        background: $white;
        z-index: 100;
        border-radius: 10px;
        li {
          display: block;
          padding: 0.5rem 0;
          border-bottom: solid 1px $red;
          &:last-child {
            border-bottom: none;
          }
          a.router-link-exact-active {
            &::after {
              background: none !important;
            }
          }
        }
      }
    }
    & a.router-link-exact-active {
      color: $pri-btn;
      background: none;
      position: relative;
      &::after {
        @extend %after;
        width: 20px;
      }
    }
  }
  .flex-nav {
    display: none;
  }
  .mobile-cart {
    display: inline-block !important;
  }
}
</style>
