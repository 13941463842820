<template>
  <router-view />
  <cart-alert />
</template>

<script>
import CartAlert from "./components/reuseables/CartAlert.vue";
export default {
  components: { CartAlert },
};
</script>

<style lang="scss">
@import "@/scss/style.scss";
</style>
