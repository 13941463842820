import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@mdi/font/css/materialdesignicons.css";
import VueSplide from "@splidejs/vue-splide";
import Flutterwave from "vue-flutterwave";

createApp(App)
  .use(store)
  .use(router)
  .use(VueSplide)
  .use(Flutterwave, { publicKey: "FLWPUBK_TEST-SANDBOXDEMOKEY-X" })
  .mount("#app");
